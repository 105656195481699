import { Component, Input, OnInit } from '@angular/core';
import { HttpService, PermissionsService, ProjectService } from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [
    trigger('animationForm', [
      state(
        'small',
        style({
          width: '45px'
        })
      ),
      state(
        'big',
        style({
          width: '225px'
        })
      ),

      transition('small => big', [
        group([
          query('.title, .icon-expand', style({ opacity: 0 })),
          query('.title, .icon-expand', [animate('0.1s', style({ opacity: 1 }))], { optional: true }),
          animate('0.23s')
        ])
      ]),

      transition('big => small', [
        group([
          query('.title, .icon-expand', style({ opacity: 1 })),
          query('.title, .icon-expand', [animate('.1s', style({ opacity: 0 }))], { optional: true }),
          animate('.23s')
        ])
      ])
    ])

    // transition('closedSecondForm => openedSecondForm, closedFirstForm => openedFirstForm', [
    //   animate('0.3s 0.2s')
    // ]),

    // trigger('animationFormTitle', [
    //   state('small', style({
    //     opacity: 0,
    //   })),
    //   state('big', style({
    //     opacity: 1,
    //   })),
    //   transition('small <=> big', [
    //     animate('1.2s')
    //   ]),
    //   // transition('closedSecondForm => openedSecondForm, closedFirstForm => openedFirstForm', [
    //   //   animate('0.3s 0.2s')
    //   // ]),
    // ])
  ]
})
export class NavComponent implements OnInit {
  @Input() sizeMenuClass: string;

  public menu: any;

  constructor(
    private httpService: HttpService,
    private projectService: ProjectService,
    public ps: PermissionsService,
    private translate: TranslateService,
    private router: Router,
    private storage:LocalStorageService
  ) {
    this.menu = [
      {
        header: 'layouts.navigation.HOME',
        visible: true,
        icon: ['fas', 'home'],
        url: ['/', 'home']
      },
      {
        header: 'layouts.navigation.EMPLOYEES',
        visible: ps.has('TAB_EMPLOYEES_DISPLAY'),
        icon: ['fas', 'user-friends'],
        url: ['/', 'employees', 'users'],
        expanded: false
      },
      {
        header: 'layouts.navigation.STATUS_MCH',
        visible: ps.has('TAB_STATUS-MERCH_DISPLAY'),//
        icon: ['far', 'calendar'],
        url: ['/', 'status-mch'],
        expanded: false
      },
      {
        header: 'layouts.navigation.LOGISTIC',
        visible: ps.has('TAB_LOGISTIC_DISPLAY'),
        icon: ['fas', 'map-marker-alt'],
        url: ['/', 'logistic', 'merchandisers'],
        expanded: false
      },
      {
        header: 'layouts.navigation.ROUTES',
        visible: ps.has('TAB_ROUTES_DISPLAY'),
        icon: ['fas', 'route'],
        url: ['/', 'routes'],
        expanded: false,
        children: [
          {
            header: 'layouts.navigation.ROUTES_ROUTE-PLAN',
            visible: ps.has('TAB_ROUTES_ROUTE-PLAN_DISPLAY'),
            url: ['/', 'routes', 'route-plans']
          },
          {
            header: 'layouts.navigation.ROUTES_LOAD-FILES',
            visible: ps.has('TAB_ROUTES_LOAD-FILES_DISPLAY'),
            url: ['/', 'routes', 'load-files']
          }
        ]
      },      {
        header: 'layouts.navigation.MATRIX',
        visible: ps.has('TAB_CL_STORES_DISPLAY'),
        icon: ['fas', 'square'],
        url: ['/', 'matrix'],
        expanded: false,
        children: [
          {
            header: 'layouts.navigation.MATRIX_STORES',
            visible: ps.has('TAB_CL_STORES_DISPLAY'),
            url: ['/', 'matrix', 'stores']
          },
          {
            header: 'layouts.navigation.MATRIX_LOADER',
            visible: ps.has('TAB_CL_GETFILES_DISPLAY'),
            url: ['/', 'matrix', 'loader']
          },
          {
            header: 'layouts.navigation.MATRIX_CONTRACT-LIST',
            visible: ps.has('TAB_CL_FORMATS_DISPLAY'),
            url: ['/', 'matrix', 'contract-list']
          },
          {
            header: 'layouts.navigation.MATRIX_FORMATS',
            visible: ps.has('TAB_CL_FORMATS_DISPLAY'),
            url: ['/', 'matrix', 'formats']
          }
        ]
      },
      {
        header: 'layouts.navigation.BI_REPORTS',
        visible: false,
        icon: ['fas', 'chart-pie'],
        url: ['/', 'bi-reports']
      },
      {
        header: 'layouts.navigation.STORES',
        visible: ps.has('TAB_STORES_DISPLAY'),
        icon: ['fas', 'store-alt'],
        url: ['/', 'stores']
      },
      {
        header: 'layouts.navigation.REPORTS',
        visible: ps.has('TAB_REPORTS_DISPLAY'),
        icon: ['fas', 'chart-pie'],
        url: ['/', 'reports'],
        expanded: false,
        children: [
          {
            header: 'layouts.navigation.REPORTS_PHOTO',
            visible: ps.has('TAB_REPORTS_PHOTO_DISPLAY'),
            url: ['/', 'reports', 'photo']
          },
          {
            header: 'layouts.navigation.REPORTS_SERVICE',
            visible: ps.has('TAB_REPORTS_SERVICE_DISPLAY'),
            url: ['/', 'reports', 'service']
          },
          {
            header: 'layouts.navigation.REPORTS_EXCEL',
            visible: ps.has('TAB_REPORTS_EXCEL_DISPLAY'),
            url: ['/', 'reports', 'excel']
          },
          {
            header: 'layouts.navigation.ORDER',
            visible: ps.has('TAB_REPORTS_EXCEL_DISPLAY'),
            url: ['/', 'reports', 'order']
          }
        ]
      },
      {
        header: 'layouts.navigation.CROWDSOUNCING',
        visible: ps.has('TAB_CROWDSOUNCING_DISPLAY'),
        icon: ['fas', 'info-circle'],
        url: ['/', 'crowdsourcing'],
        expanded: false,
        children: [
          {
            header: 'layouts.navigation.LOTS',
            visible: ps.has('TAB_REPORTS_CROWDSOUNCING_LOTS_DISPLAY'),
            url: ['/', 'crowdsourcing', 'lots']
          },
          {
            header: 'layouts.navigation.EMPLOYEERS',
            visible: ps.has('TAB_CROWDSOUNCING_EMPLOYEERS_DISPLAY'),
            url: ['/', 'crowdsourcing', 'employeers']
          },
          {
            header: 'layouts.navigation.DOCUMENTS',
            visible: ps.has('TAB_CROWDSOUNCING_EMPLOYEERS_DISPLAY'),
            url: ['/', 'crowdsourcing', 'documents']
          },
          {
            header: 'layouts.navigation.PAYMENTS',
            visible: ps.has('TAB_CROWDSOUNCING_PAYMENTS_DISPLAY'),
            url: ['/', 'crowdsourcing', 'payments']
          },
          {
            header: 'layouts.navigation.PAYMENTS_CREATE',
            visible: ps.has('TAB_CROWDSOUNCING_PAYMENTS_CREATE_DISPLAY'),
            url: ['/', 'crowdsourcing', 'payments', 'create']
          }
        ]
      },
      {
        header: 'layouts.navigation.CONSTRUCTOR_PLANOGRAMM',
        visible: false,
        icon: ['fas', 'puzzle-piece'],
        url: ['/', 'constructor']
      },
      {
        header: 'layouts.navigation.VISITS',
        visible: ps.has('TAB_VISITS_DISPLAY'),
        icon: ['fas', 'check-double'],
        url: ['/', 'visits']
      },
      {
        header: 'layouts.navigation.MASS_VISITS',
        visible: ps.has('TAB_MASSIVE_CHANGES_DISPLAY'),
        icon: ['fas', 'upload'],
        url: ['/', 'mass-visits']
      },
      {
        header: 'layouts.navigation.ADMINISTRATIONS',
        visible: ps.has('TAB_ADMINISTRATIONS_DISPLAY'),
        icon: ['fas', 'user-secret'],
        url: ['/', 'administration'],
        expanded: false,
        children: [
          {
            header: 'layouts.navigation.GET_FILES',
            visible: true,
            url: ['/', 'administration', 'get-files']
          },
          {
            header: 'layouts.navigation.PROJECTS',
            visible: true,
            url: ['/', 'administration', 'projects']
          },
          {
            header: 'layouts.navigation.APPLICATIONS',
            visible: true,
            url: ['/', 'administration', 'applications']
          },
          {
            header: 'layouts.navigation.STATUS',
            visible: true,
            url: ['/', 'administration', 'status']
          },
          {
            header: 'layouts.navigation.SMS',
            visible: true,
            url: ['/', 'administration', 'sms']
          }
        ]
      },
      {
        header: 'layouts.navigation.CAP',
        visible: ps.has('TAB_CAP_DISPLAY'),
        icon: ['fas', 'copyright'],
        url: ['/', 'other', 'cap']
      },
      {
        header: 'layouts.navigation.PROMO',
        visible: ps.has('TAB_OREH-PROMO_DISPLAY')||ps.has('TAB_DONON-PROMO_DISPLAY'),
        icon: ['far', 'check-circle'],
        url: ['/', 'other', 'promo']
      },
      {
        header: 'layouts.navigation.REQUEST',
        visible: ps.has('TAB_TASKS_DISPLAY'),
        icon: ['fas', 'tasks'],
        url: ['/', 'other', 'request']
      },
      {
        header: 'layouts.navigation.FILEHOSTING',
        visible: ps.has('TAB_FILEHOSTING_DISPLAY'),
        icon: ['fas', 'folder-open'],
        url: ['/', 'filehosting']
      }
    ];
  }
  // орех - 1 донон -2

  ngOnInit() {}

  getVisibleMenu(array) {
    return array.filter(el => el.visible === true);
  }

  expand(m, event) {
    m.expanded = !m.expanded;
  }

  logout() {
    this.storage.clear('access_token');
    //localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}
